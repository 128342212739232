import React, { useState, useEffect, useContext } from "react";
import Name from "./Cells/Name";
import Room from "./Cells/Room";
import Stage from "./Cells/Stage";
import ArrivalTime from "./Cells/ArrivalTime";
import AppointmentTime from "./Cells/AppointmentTime";
import TimeElapsed from "./Cells/TimeElapsed";
import TimeRemaining from "./Cells/TimeRemaining";
import PatientTrackingContext from "../PatientTrackingContext";
import { VisitContext } from "../VisitContext";

const cellStyle = {
    borderRight: "1px solid black",
    fontWeight: "bold",
    paddingLeft: "2px",
    paddingRight: "2px",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
    textOverflow: "ellipsis",
    fontSize: "12px",
    textAlign: "center",
    userSelect: "none"
};

export default function(props) {
    const { stages, columnLayout } = useContext(PatientTrackingContext);
    const visit = useContext(VisitContext);
    const [latestRoom, setLatestRoom] = useState({});
    const [latestStage, setLatestStage] = useState({});

    const cellDictionary = {
        Name: <Name key={"Name"} style={cellStyle}></Name>,
        Room: (
            <Room
                key={"Room"}
                style={cellStyle}
                roomId={latestRoom.roomId}
            ></Room>
        ),
        Stage: (
            <Stage
                key={"Stage"}
                style={cellStyle}
                stageId={latestStage.stageId}
            ></Stage>
        ),
        ArrivalTime: (
            <ArrivalTime key={"ArrivalTime"} style={cellStyle}></ArrivalTime>
        ),
        AppointmentTime: (
            <AppointmentTime
                key={"AppointmentTime"}
                style={cellStyle}
            ></AppointmentTime>
        ),
        TimeElapsed: (
            <TimeElapsed key={"TimeElapsed"} style={cellStyle}></TimeElapsed>
        ),
        TimeRemaining: (
            <TimeRemaining
                key={"TimeRemaining"}
                style={cellStyle}
            ></TimeRemaining>
        )
    };

    useEffect(() => {
        setLatestRoom(visit.roomHistory[visit.roomHistory.length - 1]);
        setLatestStage(visit.stageHistory[visit.stageHistory.length - 1]);
    }, [visit.stageHistory, visit.roomHistory]);

    const stageBackgroundColor =
        latestStage &&
        latestStage.stageId &&
        stages &&
        stages[latestStage.stageId] &&
        stages[latestStage.stageId].backgroundColor
            ? stages[latestStage.stageId].backgroundColor
            : "grey";

    const stageTextColor =
        latestStage &&
        latestStage.stageId &&
        stages &&
        stages[latestStage.stageId] &&
        stages[latestStage.stageId].textColor
            ? stages[latestStage.stageId].textColor
            : "white";

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${columnLayout.length}, minmax(0, 1fr))`,
                backgroundColor: stageBackgroundColor,
                color: stageTextColor,
                borderBottom: "1px solid black"
            }}
        >
            {columnLayout.map(element => {
                return cellDictionary[element];
            })}
        </div>
    );
}
