import React, { useContext } from "react";
import PatientTrackingContext from "./PatientTrackingContext";
import TopBar from "./TopBar";
import TickerContext from "./TickerContext";
import useTicker from "./useTicker";
import usePatientTracking from "./usePatientTracking";
import ColumnHeaders from "./Row/ColumnHeaders";
import StageVisits from "./StageVisits";
import StagelessVisits from "./StagelessVisits";
import { Resizable } from "re-resizable";
import GlobalContext from "../../../../../hooks/GlobalContext";
import { Card } from "@material-ui/core";

export default function(props) {
    const { localStore } = useContext(GlobalContext);
    const { ticks } = useTicker();
    const patientTracking = usePatientTracking();
    const { stages } = patientTracking;

    return (
        <React.Fragment>
            <PatientTrackingContext.Provider value={patientTracking}>
                <TopBar></TopBar>
                <TickerContext.Provider value={ticks}>
                    <Card>
                        <Resizable
                            style={{ overflowY: "scroll", overflowX: "hidden" }}
                            defaultSize={{
                                height: `${localStore.data.patientTrackingHeight}px`
                            }}
                            onResizeStop={(event, direction, refToElement, delta) => {
                                const newHeight = parseInt(refToElement.style.height);
                                localStore.update(
                                    localStore.storageKeys.patientTrackingHeight,
                                    newHeight
                                );
                            }}
                            // enable={{ bottom: true }}
                        >
                            <ColumnHeaders></ColumnHeaders>
                            {Object.keys(stages)
                                .sort((idA, idB) => {
                                    const stageA = stages[idA];
                                    const stageB = stages[idB];

                                    return stageA.name.localeCompare(
                                        stageB.name
                                    );
                                })
                                .map(stageId => {
                                    return (
                                        <StageVisits
                                            key={stageId}
                                            stageId={stageId}
                                        ></StageVisits>
                                    );
                                })}
                            <StagelessVisits></StagelessVisits>
                        </Resizable>
                    </Card>
                </TickerContext.Provider>
            </PatientTrackingContext.Provider>
        </React.Fragment>
    );
}
