import React, { useContext } from "react";
import RoomContext from "./RoomContext";
import unpagePersonFromRoom from "../../../../../../../firebase/httpsCallable/unpagePersonFromRoom";
import { ButtonBase, Tooltip } from "@material-ui/core";
import GlobalContext from "../../../../../../../hooks/GlobalContext";
import MeaningIcons from "../../Meaning/MeaningIcons";

export default function(props) {
    const { mainCtx, pagingState, pagingDoc } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { selectedPagingPersonId, setSelectedPagingPersonId } = pagingState;
    const { displayedPersonId, persons, room } = useContext(RoomContext);
    const displayedPerson = persons[displayedPersonId];
    const { persons: allPersons, meaningButtons } = pagingDoc;
    // const timePassed = Math.floor(
    //     (Date.now().valueOf() - parseInt(displayedPerson.pagedSince)) /
    //         1000 /
    //         60
    // );

    return (
        <Tooltip
            title={
                selectedPagingPersonId && allPersons[displayedPersonId] && allPersons[displayedPersonId].name
                    ? allPersons[displayedPersonId].name
                    : ""
            }
        >
            <ButtonBase
                className="displayedPerson"
                style={{
                    backgroundColor: displayedPerson.bgColor,
                    color: displayedPerson.textColor,
                    minHeight: 0,
                    padding: 0,
                    minWidth: 0
                }}
                onClick={() => {
                    if (!selectedPagingPersonId) {
                        unpagePersonFromRoom({
                            officeId,
                            personId: displayedPersonId,
                            roomId: room.roomId
                        });
                        setSelectedPagingPersonId(null);
                    }
                }}
            >
                {displayedPerson.name}
                <MeaningIcons
                    iconId={
                        (meaningButtons &&
                            meaningButtons[displayedPerson.meaningButtonId] &&
                            meaningButtons[displayedPerson.meaningButtonId]
                                .iconId) ||
                        0
                    }
                ></MeaningIcons>
            </ButtonBase>
        </Tooltip>
    );
}
