import React, { useContext, useEffect } from "react";
import { Router, Route } from "react-router-dom";
import MessagingContext from "./MessagingContext";
import ConversationList from "./ConversationList";
import Conversation from "./Conversation";

import CreateConversation from "./CreateConversation";
import { Card } from "@material-ui/core";
import GlobalContext from "../../../../../hooks/GlobalContext";
import { Resizable } from "re-resizable";

export default function(props) {
    const messaging = useContext(MessagingContext);
    const { localStore } = useContext(GlobalContext);

    return (
        <Resizable
            style={{ display: "flex", width: "50%" }}
            defaultSize={{ height: `${localStore.data.messagesHeight}px` }}
            enable={{ bottom: true }}
            onResizeStop={(event, direction, refToElement, delta) => {
                const newHeight = parseInt(refToElement.style.height);
                localStore.update(
                    localStore.storageKeys.messagesHeight,
                    newHeight
                );
            }}
        >
            <div
                style={{
                    padding: "5px",
                    width: "100%",
                    display: "grid"
                    // height: `${localStore.data.messagesHeight}px`

                    // border: "1px solid black"
                }}
            >
                {messaging.selectedUserId && (
                    <React.Fragment>
                        <Route
                            exact
                            path="/conversationList"
                            component={ConversationList}
                        ></Route>
                        <Route
                            exact
                            path="/conversation"
                            component={Conversation}
                        ></Route>
                        <Route
                            exact
                            path="/createConversation"
                            component={CreateConversation}
                        ></Route>
                    </React.Fragment>
                )}
            </div>
        </Resizable>
    );
}
